<template>
  <div>
    <el-date-picker
            v-model="selectedDateTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            :placeholder="$t('triggers.select-hours-minutes')"
            value-format="yyyy-MM-dd HH:mm"
            @change="changeSchedule">
    </el-date-picker>
  </div>
</template>

<script>

  export default {
    name: "Once",

    props: {
      value: String,
      tab: String
    },

    data: function () {
      return {
        selectedDateTime: ""
      }
    },

    watch: {
      tab: function (val) {
        console.log("Once, tab : " + val)
        if (val == "once")
          this.changeSchedule()
      }
    },

    methods: {
      changeSchedule(){
        let descEn;
        let descTr;

        console.log("selected datetime : ", this.selectedDateTime)

        if (!this.selectedDateTime)
        {
          descEn = ""
          descTr = ""
        }
        else{
          descEn = "At " + this.selectedDateTime
          descTr = "Tarih " + this.selectedDateTime + " olduğunda"
        }

        this.$emit("changeScheduleTr", { value: this.selectedDateTime, description: descTr})
        this.$emit("changeScheduleEn", { value: this.selectedDateTime, description: descEn})
      }
    },

    created(){
      console.log("Once, created")

      if (this.value) {
        var items = this.value.split(" ")
        if (items.length == 2) {
          var dateItems = items[0].split("-")
          if (dateItems.length == 3) {
            this.selectedDateTime = this.value
          }
        }
      }
    },

    activated(){
      console.log("Once, activated")
    }
  }
</script>

<style scoped>

</style>