<template>
  <div>
    <!--<el-select v-model="selectedMinute" placeholder="Select Minute" @change="changeSchedule">-->
      <!--<div slot="prefix" style="height: 100%; display: flex; align-items: center"><i style="font-size: medium" class="far fa-clock"></i></div>-->
      <!--<el-option v-for="minute in (0, 59)"-->
                 <!--:value="minute"-->
                 <!--:key="minute" :label="minute < 10 ? '0' + minute : minute"></el-option>-->
    <!--</el-select>-->

    <el-select v-model="selectedMinute" :placeholder="$t('triggers.select-minute')" @change="changeSchedule">
      <div slot="prefix" style="height: 100%; display: flex; align-items: center"><i style="font-size: medium" class="far fa-clock"></i></div>
      <el-option v-for="minute in minutes"
                 :value="minute"
                 :key="minute" :label="minute"></el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: "Hourly",
    props: {
      value: String,
      tab: String
    },

    data: function () {
      return {
        selectedMinute: this.value && this.value.length == 2 ? this.value : ""
      }
    },

    computed: {
      minutes: function () {
        return Array.from(Array(60).keys()).map((value, index) => index < 10 ? "0" + index : "" + index)
      }
    },

    watch: {
      tab: function (val) {
        console.log("Hourly, tab : " + val)
        if (val == "hourly") {
          this.changeSchedule()
        }
      }
    },

    methods: {
      changeSchedule(){
        // var minStr = this.selectedMinute < 10 ? "0" + this.selectedMinute : "" + this.selectedMinute
        // this.$emit("changeSchedule", { value: minStr, description: "In the " + this.getMinuteString() + " minute(s) per hour" })

        console.log("selected min : ", this.selectedMinute)

        let descEn;
        let descTr;

        if (!this.selectedMinute){
          descEn = ""
          descTr = ""
        }          
        else{
          descEn = "In the " + this.getMinString() + " minute(s) per hour"
          descTr = "Her saatin " + this.selectedMinute + ". dakikasında"
        }

        this.$emit("changeScheduleEn", { value: this.selectedMinute, description: descEn})
        this.$emit("changeScheduleTr", { value: this.selectedMinute, description: descTr})
      },

      getMinuteString(){
        if (this.selectedMinute == 1)
          return this.selectedMinute + "st"
        else if (this.selectedMinute == 2)
          return this.selectedMinute + "nd"
        else if (this.selectedMinute == 3)
          return this.selectedMinute + "rd"
        else
          return this.selectedMinute + "th"
      },

      getMinString(){
        var minute = this.minutes.indexOf(this.selectedMinute)
        if (minute == 1)
          return minute + "st"
        else if (minute == 2)
          return minute + "nd"
        else if (minute == 3)
          return minute + "rd"
        else
          return minute + "th"
      }
    },

    activated(){
      console.log("Hourly, activated")
    },

    created(){
      console.log("Hourly, created")
    }

  }
</script>

<style scoped>

</style>