<template>
  <div>

    <el-checkbox-group v-model="selectedDays">
      <el-checkbox-button v-for="day in daysOfWeek" :label="day" :key="day">{{$t('triggers.' + day)}}</el-checkbox-button>
    </el-checkbox-group>

    <div style="margin-top: 20px">
      <el-select v-model="selectedTimeOption" placeholder="Select">
        <el-option v-for="option in timeOptions"
                   :label="$t(option.label)"
                   :value="option.value"
                   :key="option.value">

        </el-option>
      </el-select>

      <el-time-picker v-if="selectedTimeOption != 2 && selectedTimeOption != 3"
                      v-model="selectedTime"
                      format="HH:mm"
                      value-format="HH:mm"
                      :placeholder="$t('triggers.select-hours-minutes')"
                      :picker-options="{
                        selectableRange: selectedTimeOption == 1 ? '00:00:00 - 23:59:00' : '00:00:00 - 05:59:00'
                      }"
                      style="margin-left: 10px">
      </el-time-picker>

    </div>

  </div>
</template>

<script>
import { scheduleMixin } from "./scheduleMixin";

export default {
  name: "Weekly",

  mixins: [scheduleMixin],

  props: {
    value: String,
    tab: String,
  },

  data: function() {
    return {
      selectedTime: "",
      selectedDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      selectedTimeOption: 1,
      daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      dayNames: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },

  watch: {
    selectedTime: function(val) {
      console.log("Weekly, selectedTime", val);
      if (val.length > 0) this.emitSchedule();
    },

    selectedTimeOption: function(val) {
      console.log("Weekly, selectedTimeOption", val);
      //this.selectedTime = ""
      this.emitSchedule();
    },

    selectedDays: function(val) {
      console.log("Weekly, selectedDays", val);
      this.emitSchedule();
    },

    tab: function(val) {
      console.log("Weekly, tab : " + val);
      if (val == "weekly") this.emitSchedule();
    },
  },

  methods: {
    emitSchedule() {
      if (this.selectedDays.length <= 0) {
        this.$emit("changeScheduleEn", { value: "", description: "" });
        this.$emit("changeScheduleTr", { value: "", description: "" });
        return;
      }

      // if (this.selectedTimeOption != 2 || this.selectedTimeOption != 3 && this.selectedTime.length <= 0) {
      //   this.$emit("changeSchedule", {value: "", description: ""})
      //   return
      // }

      var daysStr = this.selectedDays.join(",");

      var selectedDayNames = this.selectedDays
        .map((day) =>
          this.$t("triggers." + this.dayNames[this.daysOfWeek.indexOf(day)])
        )
        .join(", ");

      var timeOption = this.timeOptions.find(
        (option) => option.value == this.selectedTimeOption
      );

      if (this.selectedTimeOption == 1) {
        let descEn = ""
        let descTr = ""

          descEn =
            "At " +
            this.selectedTime +
            " " +
            this.descriptionOfDaysEn(selectedDayNames);
          descTr =
            this.descriptionOfDaysTr(selectedDayNames) +
            " saat " +
            this.selectedTime +
            " olduğunda";

        this.$emit("changeScheduleEn", {
          value: daysStr + " " + this.selectedTime,
          description: descEn,
        });
        this.$emit("changeScheduleTr", {
          value: daysStr + " " + this.selectedTime,
          description: descTr,
        });
      } else if (this.selectedTimeOption == 2 || this.selectedTimeOption == 3) {
        let descEn = ""
        let descTr = ""
          descEn =
            "At " +
            timeOption.type +
            " " +
            this.descriptionOfDaysEn(selectedDayNames);
          descTr =
            this.descriptionOfDaysTr(selectedDayNames) +
            " " +
            timeOption.labelTr;

        this.$emit("changeScheduleEn", {
          value: daysStr + " " + timeOption.type,
          description: descEn,
        });
        this.$emit("changeScheduleTr", {
          value: daysStr + " " + timeOption.type,
          description: descTr,
        });
      } else if (this.selectedTimeOption == 4 || this.selectedTimeOption == 5) {
        if (this.selectedTime.includes(":")) {
          let timeItems = this.selectedTime.split(":");
          let h = timeItems[0].startsWith("0")
            ? timeItems[0].substring(1)
            : timeItems[0];
          let m = timeItems[1].startsWith("0")
            ? timeItems[1].substring(1)
            : timeItems[1];

        let descEn = ""
        let descTr = ""
            descEn =
              h +
              " hour(s) " +
              m +
              " minute(s) before " +
              timeOption.type +
              " " +
              this.descriptionOfDaysEn(selectedDayNames);
            descTr =
              this.descriptionOfDaysTr(selectedDayNames) +
              " " +
              timeOption.typeTr +
              "ndan " +
              h +
              " saat " +
              m +
              " dakika önce";
          this.$emit("changeScheduleEn", {
            value: daysStr + " " + timeOption.type + "-" + this.selectedTime,
            description: descEn,
          })
          this.$emit("changeScheduleTr", {
            value: daysStr + " " + timeOption.type + "-" + this.selectedTime,
            description: descTr,
          });
        }
      } else if (this.selectedTimeOption == 6 || this.selectedTimeOption == 7) {
        if (this.selectedTime.includes(":")) {
          let timeItems = this.selectedTime.split(":");
          let h = timeItems[0].startsWith("0")
            ? timeItems[0].substring(1)
            : timeItems[0];
          let m = timeItems[1].startsWith("0")
            ? timeItems[1].substring(1)
            : timeItems[1];

          let descEn = ""
          let descTr = ""
            descEn =
              h +
              " hour(s) " +
              m +
              " minute(s) after " +
              timeOption.type +
              " " +
              this.descriptionOfDaysEn(selectedDayNames);
            descTr =
              this.descriptionOfDaysTr(selectedDayNames) +
              " " +
              timeOption.typeTr +
              "ndan " +
              h +
              " saat " +
              m +
              " dakika sonra";
          this.$emit("changeScheduleEn", {
            value: daysStr + " " + timeOption.type + "+" + this.selectedTime,
            description: descEn,
          });
          this.$emit("changeScheduleTr", {
            value: daysStr + " " + timeOption.type + "+" + this.selectedTime,
            description: descTr,
          });
        }
      }
    },

    descriptionOfDaysEn(daysStr) {
      const days = daysStr.split(", ");

      const existSunday = days.includes(this.$t("triggers.Sunday"));
      const existSaturday = days.includes(this.$t("triggers.Saturday"));
      console.log(
        "days",
        days,
        this.$t("triggers.Saturday"),
        this.$t("triggers.Sunday")
      );
      console.log("result", existSaturday, existSunday);

      if (this.selectedDays.length == 2) {
        if (existSaturday && existSunday) {
          return "on weekends";
        }
      } else if (this.selectedDays.length == 5) {
        if (!existSaturday && !existSunday) {
          return "on weekdays";
        }
      } else if (this.selectedDays.length == 7) {
        return "every day";
      }
        return "on " + daysStr;
    },
    descriptionOfDaysTr(daysStr) {
      const days = daysStr.split(", ");

      const existSunday = days.includes(this.$t("triggers.Sunday"));
      const existSaturday = days.includes(this.$t("triggers.Saturday"));
      console.log(
        "days",
        days,
        this.$t("triggers.Saturday"),
        this.$t("triggers.Sunday")
      );
      console.log("result", existSaturday, existSunday);

      if (this.selectedDays.length == 2) {
        if (existSaturday && existSunday) {
          return "Hafta sonları";
        }
      } else if (this.selectedDays.length == 5) {
        if (!existSaturday && !existSunday) {
          return "Hafta içi her gün";
        }
      } else if (this.selectedDays.length == 7) {
        return "Her gün";
      }

      if (this.selectedDays.length > 1) return daysStr + " günleri";
      else return daysStr + " günü";
    },
  },

  created() {
    if (this.value) {
      var items = this.value.split(" ");

      if (items.length == 2) {
        var days = items[0];
        if (days.length > 0) {
          var dayList = days.split(",");
          dayList = dayList.filter((day) => this.daysOfWeek.includes(day));
          if (dayList.length > 0) {
            this.selectedDays = dayList;
            console.log("Weekly, items", items[0], items[1]);

            if (items[1].includes("sunrise") || items[1].includes("sunset")) {
              if (items[1] === "sunrise") {
                this.selectedTimeOption = 2;
                this.selectedTime = "";
              } else if (items[1] === "sunset") {
                this.selectedTimeOption = 3;
                this.selectedTime = "";
              } else if (items[1].startsWith("sunrise-")) {
                var sunItems = items[1].split("-");
                this.selectedTimeOption = 4;
                this.selectedTime = sunItems[1];
              } else if (items[1].startsWith("sunset-")) {
                var sunItems = items[1].split("-");
                this.selectedTimeOption = 5;
                this.selectedTime = sunItems[1];
              } else if (items[1].startsWith("sunrise+")) {
                var sunItems = items[1].split("+");
                this.selectedTimeOption = 6;
                this.selectedTime = sunItems[1];
              } else if (items[1].startsWith("sunset+")) {
                var sunItems = items[1].split("+");
                this.selectedTimeOption = 7;
                this.selectedTime = sunItems[1];
              }
            } else {
              this.selectedTimeOption = 1;
              this.selectedTime = items[1];
            }
          }
        }
      }
    }

    console.log("Weekly, created", this.value, this.selectedDays);
  },

  activated() {
    console.log("Weekly, activated");
  },
};
</script>

<style scoped>
</style>
