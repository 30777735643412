var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: "datetime",
          format: "yyyy-MM-dd HH:mm",
          placeholder: _vm.$t("triggers.select-hours-minutes"),
          "value-format": "yyyy-MM-dd HH:mm"
        },
        on: { change: _vm.changeSchedule },
        model: {
          value: _vm.selectedDateTime,
          callback: function($$v) {
            _vm.selectedDateTime = $$v
          },
          expression: "selectedDateTime"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }