var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: _vm.$t("triggers.select-minute") },
          on: { change: _vm.changeSchedule },
          model: {
            value: _vm.selectedMinute,
            callback: function($$v) {
              _vm.selectedMinute = $$v
            },
            expression: "selectedMinute"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "100%",
                display: "flex",
                "align-items": "center"
              },
              attrs: { slot: "prefix" },
              slot: "prefix"
            },
            [
              _c("i", {
                staticClass: "far fa-clock",
                staticStyle: { "font-size": "medium" }
              })
            ]
          ),
          _vm._l(_vm.minutes, function(minute) {
            return _c("el-option", {
              key: minute,
              attrs: { value: minute, label: minute }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }