var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("triggers.schedule"),
          actionButtonIcon: "fa fa-save"
        },
        on: { action: _vm.handleAddSchedule }
      }),
      _vm.conditionalDescriptionEn && _vm.$i18n.locale == "en"
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.conditionalDescriptionEn) }
          })
        : _vm._e(),
      _vm.conditionalDescriptionTr && _vm.$i18n.locale == "tr"
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.conditionalDescriptionTr) }
          })
        : _vm._e(),
      _c(
        "el-tabs",
        {
          staticStyle: { margin: "20px" },
          attrs: { type: "border-card" },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        _vm._l(_vm.components, function(component) {
          return _c(
            "el-tab-pane",
            { key: component.name, attrs: { name: component.name } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { class: component.icon }),
                _vm._v(" " + _vm._s(_vm.$t("triggers." + component.label)))
              ]),
              _c(component.component, {
                tag: "component",
                attrs: { tab: _vm.selectedTab, value: _vm.conditionalValue },
                on: {
                  changeScheduleTr: _vm.changeScheduleTr,
                  changeScheduleEn: _vm.changeScheduleEn
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }