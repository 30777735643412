<template>
  <div>
    <app-navigation :title="$t('triggers.schedule')" actionButtonIcon="fa fa-save" @action="handleAddSchedule"></app-navigation>

    <div v-if="conditionalDescriptionEn && $i18n.locale =='en'" class="description" v-html="conditionalDescriptionEn"></div>
    <div v-if="conditionalDescriptionTr && $i18n.locale =='tr'" class="description" v-html="conditionalDescriptionTr"></div>

    <!-- <div v-if="conditionalDescription" class="description">
      {{ conditionalDescription }}
    </div> -->

    <el-tabs type="border-card" style="margin: 20px;" v-model="selectedTab">

      <el-tab-pane v-for="component in components" :key="component.name" :name="component.name">
        <span slot="label"><i :class="component.icon"></i> {{ $t("triggers." + component.label) }}</span>

        <component :is="component.component" :tab="selectedTab" :value="conditionalValue" @changeScheduleTr="changeScheduleTr" @changeScheduleEn="changeScheduleEn"></component>

      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation'
  import Hourly from "./schedule/Hourly"
  import Weekly from "./schedule/Weekly"
  import Monthly from "./schedule/Monthly"
  import Once from "./schedule/Once"

  export default {
    name: "WhenSchedule",
    components: {
      'app-navigation': Navigation,
      Hourly,
      Weekly,
      Monthly,
      Once
    },

    data: function () {
      return {
        conditionalValue: "",
        conditionalDescriptionEn: "",
        conditionalDescriptionTr: "",
        conditionalDescription: "",
        selectedTab: "hourly",

        components: [
          { name: "hourly", label: ("hourly"), icon: "fa fa-clock", component: Hourly },
          { name: "weekly", label: ("weekly"), icon: "fa fa-calendar-week", component: Weekly },
          { name: "monthly", label: ("monthly"), icon: "fa fa-calendar-alt", component: Monthly },
          { name: "once", label: ("once"), icon: "fa fa-calendar-day", component: Once },
        ]
      }
    },

    computed: {
      condition: function () {
        return this.$store.getters.triggerData.condition
      },

    },


    methods: {
      changeScheduleEn(data){
        //console.log("selected time", data, this.selectedTab)
        this.conditionalValue = data.value
        this.conditionalDescriptionEn = data.description
        this.conditionalDescription = data.description
      },
      changeScheduleTr(data){
        //console.log("selected time", data, this.selectedTab)
        this.conditionalValue = data.value
        this.conditionalDescriptionTr = data.description
        this.conditionalDescription = data.description
      },

      handleAddSchedule(){

        console.log("handleAddSchedule", this.conditionalValue, this.selectedTab)

        var condition = {
          id: 1,
          symbol: this.selectedTab == "hourly" || this.selectedTab == "once" ? "=" : "[]",
          category: "Schedule",
          identifier: this.selectedTab,
          value: this.conditionalValue,
          description: this.conditionalDescription
        }

        if (this.conditionalValue.includes("sunset") || this.conditionalValue.includes("sunrise")) {
          condition.property = "sun"
        } else {
          condition.property = "time"
        }


        this.$store.commit("saveCondition", {
          path: "",
          condition: condition
        })

        if (this.$route.params.edit)
          this.$router.go(-1)
        else
          this.$router.push({name: 'TriggerAdd'})
      },

    },


    created(){
      if (this.condition.identifier) {
        this.selectedTab = this.condition.identifier
        this.conditionalDescription = this.condition.description
        this.conditionalDescriptionEn = this.condition.description
        this.conditionalDescriptionTr = this.condition.description
        this.conditionalValue = this.condition.value
      }

    }
  }
</script>

<style scoped>
  .description {
    display: flex;
    justify-content: center;
    margin: 10px 15px 25px 15px;
    padding: 15px;
    border: 1px solid #eeeeee;
    background-color: #fff;
  }
</style>