<template>
  <div>

    <!--<el-checkbox-group v-model="selectedDays">-->
      <!--<el-checkbox-button v-for="day in (1,31)" :label="day" :key="day">{{day}}</el-checkbox-button>-->
    <!--</el-checkbox-group>-->

    <el-checkbox-group v-model="selectedDays">
      <el-checkbox-button v-for="day in daysOfMonth" :label="day" :key="day">{{day}}</el-checkbox-button>
    </el-checkbox-group>

    <div style="margin-top: 20px">
      <el-select v-model="selectedTimeOption" placeholder="Select">
        <el-option v-for="option in timeOptions"
                   :label="$t(option.label)"
                   :value="option.value"
                   :key="option.value">

        </el-option>
      </el-select>

      <el-time-picker v-if="selectedTimeOption != 2 && selectedTimeOption != 3"
                      v-model="selectedTime"
                      format="HH:mm"
                      value-format="HH:mm"
                      :placeholder="$t('triggers.select-hours-minutes')"
                      :picker-options="{
                        selectableRange: selectedTimeOption == 1 ? '00:00:00 - 23:59:00' : '00:00:00 - 05:59:00'
                      }"
                      style="margin-left: 10px">
      </el-time-picker>

    </div>

  </div>
</template>

<script>
  import {scheduleMixin} from "./scheduleMixin"

  export default {
    name: "Monthly",

    mixins: [scheduleMixin],

    props: {
      value: String,
      tab: String
    },

    data: function(){
      return {
        selectedTime: "",
        selectedDays: [],
        selectedTimeOption: 1,
      }
    },

    // data: function(){
    //   return {
    //     daysOfMonth: Array.from(Array(31).keys()).map((value, index) => {
    //       var day = index + 1
    //       return day < 10 ? "0" + day : "" + day
    //     })
    //   }
    // },

    computed: {
      daysOfMonth: function () {
        return Array.from(Array(31).keys()).map((value, index) => {
          var day = index + 1
          return day < 10 ? "0" + day : "" + day
        })
      }
    },


    watch: {
      selectedTime: function (val) {
        console.log("Monthly, selectedTime", val)
        if (val.length > 0)
          this.emitSchedule()
      },

      selectedTimeOption: function (val) {
        console.log("Monthly, selectedTimeOption", val)
        //this.selectedTime = ""
        this.emitSchedule()
      },

      selectedDays: function (val) {
        console.log("Monthly, selectedDays", val)
        this.emitSchedule()
      },

      tab: function (val) {
        console.log("Monthly, tab : " + val)
        if (val == "monthly") {
          this.emitSchedule()
        }
      }
    },

    methods: {
      emitSchedule(){
        if (this.selectedDays.length <= 0) {
          this.$emit("changeScheduleEn", "")
          this.$emit("changeScheduleTr", "")
          return
        }

        // if (this.selectedTimeOption != 2 && this.selectedTimeOption != 3 && this.selectedTime.length <= 0) {
        //   this.$emit("changeSchedule", "")
        //   return
        // }

        var daysStr = this.selectedDays.join(",")

        var dayNamesEn = this.selectedDays.map(sDay => {
          var day = this.daysOfMonth.indexOf(sDay) + 1
          
          if (day == 1)
            return day + ("st")
          else if (day == 2)
            return day + ("nd")
          else if (day == 3)
            return day + ("rd")
          else
            return day + ("th")
        }).join(", ")

        var dayNamesTr = this.selectedDays.map(sDay => {
          var day = this.daysOfMonth.indexOf(sDay) + 1
          return day + (".")

        }).join(", ")

        console.log("Monthly, emitSchedule", daysStr)


        var timeOption =  this.timeOptions.find(option => option.value == this.selectedTimeOption)
        if (this.selectedTimeOption == 1) {

          let descEn = ""
          let descTr = ""

            descEn = "At " + this.selectedTime + " " + this.descriptionOfDaysEn(dayNamesEn)
            descTr = this.descriptionOfDaysTr(dayNamesTr) + " saat " + this.selectedTime + " olduğunda"

          this.$emit("changeScheduleEn", {
            value: daysStr + " " + this.selectedTime,
            description: descEn
          })
          this.$emit("changeScheduleTr", {
            value: daysStr + " " + this.selectedTime,
            description: descTr
          })
        } else if (this.selectedTimeOption == 2 || this.selectedTimeOption == 3){
          let descEn = ""
          let descTr = ""

            descEn = "At " + timeOption.type + " " + this.descriptionOfDaysEn(dayNamesEn)
            descTr = this.descriptionOfDaysTr(dayNamesTr) + " " + timeOption.typeTr + "nda"

          this.$emit("changeScheduleEn", {
            value: daysStr + " " + timeOption.type,
            description: descEn
          })
          this.$emit("changeScheduleTr", {
            value: daysStr + " " + timeOption.type,
            description: descTr
          })
        } else if (this.selectedTimeOption == 4 || this.selectedTimeOption == 5){
          if (this.selectedTime.includes(":")) {
            let timeItems = this.selectedTime.split(":")
            let h = timeItems[0].startsWith("0") ? timeItems[0].substring(1) : timeItems[0]
            let m = timeItems[1].startsWith("0") ? timeItems[1].substring(1) : timeItems[1]

            let descEn = ""
            let descTr = ""

              descEn = h + " hour(s) " + m + " minute(s) before " + timeOption.type + " " + this.descriptionOfDaysEn(dayNamesEn)
              descTr = this.descriptionOfDaysTr(dayNamesTr) + " " + timeOption.typeTr + "ndan " + h + " saat " + m + " dakika önce" 

            this.$emit("changeScheduleEn", {
              value: daysStr + " " + timeOption.type + "-" + this.selectedTime,
              description: descEn
            })
            this.$emit("changeScheduleTr", {
              value: daysStr + " " + timeOption.type + "-" + this.selectedTime,
              description: descTr
            })
          }
        } else if (this.selectedTimeOption == 6 || this.selectedTimeOption == 7){
          if (this.selectedTime.includes(":")) {
            let timeItems = this.selectedTime.split(":")
            let h = timeItems[0].startsWith("0") ? timeItems[0].substring(1) : timeItems[0]
            let m = timeItems[1].startsWith("0") ? timeItems[1].substring(1) : timeItems[1]

            let descEn = ""
            let descTr = ""

              descEn = h + " hour(s) " + m + " minute(s) after " + timeOption.type + " " + this.descriptionOfDaysEn(dayNamesEn)
              descTr = this.descriptionOfDaysTr(dayNamesTr) + " " + timeOption.typeTr + "ndan " + h + " saat " + m + " dakika sonra" 

            this.$emit("changeScheduleEn", {
              value: daysStr + " " + timeOption.type + "+" + this.selectedTime,
              description: descEn
            })
            this.$emit("changeScheduleTr", {
              value: daysStr + " " + timeOption.type + "+" + this.selectedTime,
              description: descTr
            })
          }
        }

      },

      descriptionOfDaysEn(daysStr){
        return "on the " + daysStr + " day(s) of every Month"

      },
      descriptionOfDaysTr(daysStr){
          if(this.selectedDays.length > 1)
            return "Her ayın " + daysStr + " günlerinde"
          else
            return "Her ayın " + daysStr + " günü"
      }
    },


    created(){
      if (this.value) {
        var items = this.value.split(" ")

        if (items.length == 2) {
          var days = items[0]
          if (days.length > 0) {

            var dayList = days.split(",")
            dayList = dayList.filter(day => this.daysOfMonth.includes(day))
            if (dayList.length > 0) {
              this.selectedDays = dayList

              console.log("Monthly, items", items[0], items[1])

              if (items[1].includes("sunrise") || items[1].includes("sunset")) {
                if (items[1] === "sunrise") {
                  this.selectedTimeOption = 2
                  this.selectedTime = ""
                } else if (items[1] === "sunset") {
                  this.selectedTimeOption = 3
                  this.selectedTime = ""
                } else if (items[1].startsWith("sunrise-")) {
                  var sunItems = items[1].split("-")
                  this.selectedTimeOption = 4
                  this.selectedTime = sunItems[1]
                } else if (items[1].startsWith("sunset-")) {
                  var sunItems = items[1].split("-")
                  this.selectedTimeOption = 5
                  this.selectedTime = sunItems[1]
                } else if (items[1].startsWith("sunrise+")) {
                  var sunItems = items[1].split("+")
                  this.selectedTimeOption = 6
                  this.selectedTime = sunItems[1]
                } else if (items[1].startsWith("sunset+")) {
                  var sunItems = items[1].split("+")
                  this.selectedTimeOption = 7
                  this.selectedTime = sunItems[1]
                }

              } else {
                this.selectedTimeOption = 1
                this.selectedTime = items[1]
              }
            }

          }
        }
      }

      console.log("Monthly, created", this.value, this.selectedDays)
    },

    activated(){
      console.log("Monthly, activated")
    }
  }
</script>

<style scoped>

</style>