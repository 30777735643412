var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.selectedDays,
            callback: function($$v) {
              _vm.selectedDays = $$v
            },
            expression: "selectedDays"
          }
        },
        _vm._l(_vm.daysOfWeek, function(day) {
          return _c("el-checkbox-button", { key: day, attrs: { label: day } }, [
            _vm._v(_vm._s(_vm.$t("triggers." + day)))
          ])
        }),
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedTimeOption,
                callback: function($$v) {
                  _vm.selectedTimeOption = $$v
                },
                expression: "selectedTimeOption"
              }
            },
            _vm._l(_vm.timeOptions, function(option) {
              return _c("el-option", {
                key: option.value,
                attrs: { label: _vm.$t(option.label), value: option.value }
              })
            }),
            1
          ),
          _vm.selectedTimeOption != 2 && _vm.selectedTimeOption != 3
            ? _c("el-time-picker", {
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  format: "HH:mm",
                  "value-format": "HH:mm",
                  placeholder: _vm.$t("triggers.select-hours-minutes"),
                  "picker-options": {
                    selectableRange:
                      _vm.selectedTimeOption == 1
                        ? "00:00:00 - 23:59:00"
                        : "00:00:00 - 05:59:00"
                  }
                },
                model: {
                  value: _vm.selectedTime,
                  callback: function($$v) {
                    _vm.selectedTime = $$v
                  },
                  expression: "selectedTime"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }