export const scheduleMixin = {

  data: function () {
    return {
      timeOptions: [
        {  label: "triggers.at-time-of-day", value: 1, type: "time", typeTr: 'zaman', labelTr: 'Günün belli bir saatinde' },
        {  label: "triggers.at-sunrise", value: 2, type: "sunrise", typeTr: 'gün doğumu', labelTr: 'Gün Doğumunda' },
        {  label: "triggers.at-sunset", value: 3, type: "sunset", typeTr: 'gün batımı', labelTr: 'Gün Batımında' },
        {  label: "triggers.before-sunrise", value: 4, type: "sunrise", typeTr: 'gün doğumu', labelTr: 'Gün Doğumundan Önce' },
        {  label: "triggers.before-sunset", value: 5, type: "sunset", typeTr: 'gün batımı', labelTr: 'Gün Batımından Önce' },
        {  label: "triggers.after-sunrise", value: 6, type: "sunrise", typeTr: 'gün doğumu', labelTr: 'Gün Doğumundan Sonra' },
        {  label: "triggers.after-sunset", value: 7, type: "sunset", typeTr: 'gün batımı', labelTr: 'Gün Batımından Sonra' },
      ]
    }
  },
}